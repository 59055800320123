<template>
<div class="tgju-widget-content full-padding">
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <div :style="'display : ' + (widgetLoad ? 'none' : 'unset')">
        <div id="daily_chart_container"></div>
        <div class="list-widget list-dash-items list-widget-structure">
            <div class="tgju-widget light">
                <div :style="'display : ' + (widgetLoad ? 'none' : 'unset')">
                    <div class="row p-2">
                        <div class="col-4">
                            <label for="select-year">انتخاب سال</label>
                            <select id="select-year" v-model="select_year">
                                <option v-for="(year,index) in selectBoxValues.years" :key="year" :selected="(index + 1) == selectBoxValues.years.length ? 'selected' : '' "> {{ year }} </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <label for="select-month">انتخاب ماه</label>
                            <select id="select-month" v-model="select_month">
                                <option v-for="month in selectBoxValues.months" :key="month"> {{ month }} </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <label for="select-day">انتخاب روز</label>
                            <select id="select-day" v-model="select_day">
                                <option v-for="day in selectBoxValues.days" :key="day"> {{ day }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <button class="btn btn-success" @click="getArchiveByDate" style="margin: auto; text-align: center; padding: 15px;">
                            نتیجه
                        </button>
                    </div>
                    <hr>
                    <div style="margin: auto; text-align: center; width: 100%;">
                        <h5>
                            {{ formatPrice(result) }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.high.dir,
.low.dir {
    max-height: 25px;
    margin: 10px;
}
</style>

<script>
// در این کامپوننت تمام اجزای بخش آرشیو پروفایل شاخص در بازار را دارا می باشد
import moment from "moment-jalaali";

export default {
    name: "MarketArchiveComponent",
    data: function () {
        return {
            widgetLoad: true,
            selectBoxValues: [],
            select_year: 0,
            select_month: 1,
            select_day: 1,
            result: 0,
        };
    },
    mounted() {
        this.archiveRequests();
    },
    methods: {
        // این متد برای نمایش زمان به صورت رلتیو می باشد
        time() {
            return moment().format("LTS");
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این متد لیست شاخص هایی که قابلیت آرشیو دارند را بر می گرداند
        archiveRequests() {
            // جهت دریافت اطلاعات لیست باکس آرشیو
            this.$helpers
                .makeRequest("GET", '/market/archive-list-info')
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.selectBoxValues = api_response.data.response.detail;
                        this.select_year = this.selectBoxValues.years[this.selectBoxValues.years.length - 1];
                        this.widgetLoad = false;
                    }
                });

            //  جهت دریافت اطلاعات پروفایل شاخص 
            this.$helpers
                .makeRequest("GET", `/market/profile/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_profile = api_response.data.response.detail;
                        this.$parent.title = this.market_profile.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد برای دریافت آرشیو بر اساس تاریخ انتخاب شده
        getArchiveByDate() {
            this.$helpers
                .makeRequest("POST", '/market/result-archive', {
                    symbol: this.$route.params.name,
                    year: this.select_year,
                    month: this.select_month,
                    day: this.select_day
                })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.result = api_response.data.response.detail.price;
                    }
                });
        },
    },
};
</script>
