<template>
<div class="stocks-profile" style="position: relative;">
    <div class="stocks-header" v-if="market_header_info" style="margin-bottom: 0 !important;">
        <div class="stocks-header-main">
    <div class="fs-row stocks-header-content" style="position: relative;">
        <div class="fs-cell fs-xl-5 fs-lg-5 fs-md-12 fs-sm-12 fs-xs-12 top-header-item-block-1">
            <div class="avatar-box">
                <div class="avatar m-avatar-question-box" :style="avatarFinder">
                    <div class="-show-in-m m-left m-avatar-question profile-q-t in-title-question btn-icon-tour mobile-profile-tour">
                        <span class="fa fa-question-circle"></span>
                        <span class="circle-mask"></span>
                        <span class="throb"></span>
                    </div>
                </div>
            </div>
            <div class="header-items-p title-top">
                <div class="line">
                    <h1 class="title" style="font-size: 20px;">
                        {{ market_header_info.title }}
                    </h1>
                </div>
                <div class="line header-tag" style="font-size: 15px;position: relative;top: -4px;">
                    <div>{{ market_header_info.title_en }}</div>
                </div>
            </div>
            <div class="top-mobile-block">
                <div class="block-last-change-percentage">
                    <span class="title">نرخ فعلی :</span>
                    <span class="price" data-col="info.last_trade.PDrCotVal">{{ market_header_summary.price.value }}</span>
                    <span class="change-tag" data-col="info.last_trade.last_change_percentage">
                        <span :class="market_header_summary.yesterday_dt.plain">{{market_header_summary.yesterday_dp.plain}}</span>
                    </span>
                </div>
                <div class="top-mobile-block-cat">
                    <span>{{ market_header_info.category_title}}</span>
                </div>
            </div>
        </div>
        <div class="fs-cell fs-xl-3 fs-lg-3 fs-md-6 fs-sm-12 fs-xs-12 top-header-item-block-2 mobile-top-item-hide">
            <div class="header-items-p">
                <h3 class="line clearfix mobile-hide-block">
                    <span class="label"><i class="square background-yello"></i> نرخ فعلی :</span>
                    <span class="value">
                        <span data-col="info.last_trade.PDrCotVal">{{ market_header_summary.price.value }}</span>
                        <span class="change-tag" data-col="info.last_trade.last_change_percentage">
                            <span :class="market_header_summary.yesterday_dt.plain">{{ market_header_summary.yesterday_dp.plain }}</span>
                        </span>
                    </span>
                </h3>
                <h3 class="line clearfix mobile-top-item-hide">
                    <span class="label"><i class="square background-yello"></i>واحد حجمی :</span>
                    <span class="value">{{ market_header_info.unit }} </span>
                </h3>
            </div>
        </div>
        <div class="fs-cell fs-xl-2 fs-lg-2 fs-md-6 fs-sm-6 fs-xs-6 top-header-item-block-3">
            <div class="header-items-p">
                <h3 class="line clearfix mobile-top-item-show mobile-hide-block">
                    <span class="label"><i class="square background-yello"></i>نرخ فعلی :</span>
                    <span class="value">
                        <span data-col="info.last_trade.PDrCotVal">{{ market_header_summary.price.value }}</span>
                        <span class="change-tag" data-col="info.last_trade.last_change_percentage">
                            <span :class="market_header_summary.yesterday_dt.plain">{{ market_header_summary.yesterday_dp.plain }}</span>
                        </span>
                    </span>
                </h3>
                <h3 class="line clearfix">
                    <span class="label"><i class="square background-yello"></i>واحد پولی :</span>
                    <span class="value">{{ market_header_info.currency_unit }}</span>
                </h3>
                <h3 class="line clearfix">
                    <span class="label"><i class="square background-yello"></i>کشور :</span>
                    <span class="value">{{ market_header_info.country }}</span>
                </h3>
            </div>
        </div>
        <div class="fs-cell fs-xl-2 fs-lg-2 fs-md-6 fs-sm-6 fs-xs-6 top-header-item-block-4">
            <div class="header-items-p">
                <h3 class="line clearfix mobile-hide-block">
                    <span class="label"><i class="square background-yello"></i>شاخه :</span>
                    <span class="value">{{ market_header_info.category_title }}</span>
                </h3>
                <h3 class="line clearfix mobile-top-item-show" style="position: relative;">
                    <span class="label"><i class="square background-yello"></i>واحد حجمی :</span>
                    <span class="value">{{ market_header_info.unit }}</span>
                </h3>
                <h3 class="line clearfix">
                    <span class="label"><i class="square background-yello"></i>نوع بازار :</span>
                    <span class="value">{{ market_header_info.market_type_label }}</span>
                </h3>
            </div>
        </div>
    </div>
</div>
    </div>
    <div v-else class="stocks-header-loading">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </div>
</div>

</template>
<style>
        html .widgets .tgju-widget .table > tbody > tr > td {
            height: auto !important;
        }
        .first-block-main-tab{
            margin-bottom: 20px !important;
        }
        @media (max-width: 580px) {

            html body.mprofile .tgju-widgets-block {
                padding: 0 !important;
            }
            html body.mprofile .widgets .tgju-widget{
                border-radius: 0;
            }
            body.profile .profile-indicator .tgju-widget .table-mobile-grid tbody {
                padding: 5px 10px !important;
            }
            .first-block-main-tab{
                margin-bottom: 5px !important;
            }
        }
    /* .tgju-widget-content.full-padding{
        height: auto !important;
    }
    .widgets .tgju-widget.has-icon {
        overflow: hidden;
        border-radius: 0 !important;
    }
    body.profile .header-items-p .line .title {
        font-size: 18px !important;
    }
    .market-main-sub-tabs {
        padding: 12px 10px 15px;
    }

    .widgets .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 10px 20px;
        border-radius: 6px !important;
    }
    .invest-item .table > thead > tr > th {
        padding: 14px 15px 13px;
    }
    @media (min-width: 991px) {
        .dash-full-height .widget-min-box, .dash-full-height .tgju-widget-content, .dash-full-height .tgju-widget.light {
            height: auto !important;
        }
        .bootstrap-fix .table td,
        .bootstrap-fix .table th{
            padding: 10px 15px 9px !important;
            line-height: 1.428571429;
        }
        .desktop-m-top-20{
            margin-top: 20px;
        }

        .tgju-widget-content.full-padding {
            width: 100%;
        }
    }

    @media (max-width: 991px) {
        
        html body.profile .col-12 {
            padding: 0 5px !important;
        }
        html body.profile .widgets .tgju-widgets-row .tgju-widgets-block {
            margin-bottom: 5px;
        }
        .overview-full-t{
           width: 100% !important;
            max-width: unset !important;
            flex: auto !important; 
            margin-top:5px;
        }
        .overview-full-t .high::before,
        .overview-full-t .low::before {
            margin: 0 15px;
        }
       

        html body.profile .bootstrap-fix .row {
            display: block;
        }
        .widgets .tgju-widget.has-icon {
            margin-bottom: 5px !important;
        }
    }


    .chart-element {
        position: relative;
        top: -10px;
    }
    html .widgets .tgju-widget .tgju-widget-title.dot::before {
        background: #eec62a !important;
    }
    .widgets .dataTables_wrapper {
     overflow: hidden;
    }
    .widgets .tgju-widget .tables-data .dataTables_wrapper {
        padding-bottom: 20px;
    }
    .dashboard-dev.dashboard-main.sidebar-set-full.bootstrap-fix.widgets {
        padding: 0 0 10px !important;
    }
    .dashboard-dev.dashboard-main.sidebar-set-full.bootstrap-fix.widgets {
        overflow-x: hidden;
    } */
</style>

<script>
export default {
    name: 'MarketHeader',
    props: ['data'],
    data() {
        return {
            market_header_info: null,
            market_header_summary: null,
        }
    },
    mounted() {
        this.getProfileHeader();
    },
    destroyed() {
        $('body').removeClass('internal profile fs-grid');
    },
    computed: {
        // آواتار یک شاخص را پیدا می کند
        avatarFinder: function () {
            if (this.market_header_info.image) {
                return  {
                    'position' : 'relative',
                    'background-image': 'url(https://platform.tgju.org/files/images/' + this.market_header_info.image + ')',
                }
            } else if ( this.market_header_info.country_code && this.market_header_info.country_code != 'wd'){
                return  {
                    'position' : 'relative',
                    'background-size': '140%',
                    'background-image': 'url(https://www.tgju.org/images/flags/4x3/' + this.market_header_info.country_code + '.svg)',
                }
            }

            return  {
                'position' : 'relative',
                'background-image': 'url(https://static.tgju.org/images/no-icon.png)',
            }
        },
    },
    methods: {
        getProfileHeader() {
            this.$helpers
                .makeRequest("GET", `/market/profile-header/${this.data}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_header_info = api_response.data.response.detail.response.info;
                        this.market_header_summary = api_response.data.response.detail.response.summary;
                        $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        $('body').addClass('internal profile fs-grid mprofile');
                         
                    }
                });
        },
    },
}
</script>