const static_market_nav = {
    summary: {
        title: 'در یک نگاه',
        name: 'MarketProfile',
        icon: 'uil uil-layer-group',
        hide: false,
    },
    today: {
        title: 'شاخص در روز جاری',
        name: 'MarketToday',
        icon: 'uil uil-arrow-growth',
        hide: false,
    },
    history: {
        title: 'تاریخچه',
        name: 'MarketHistory',
        icon: 'uil uil-calendar-alt',
        hide: false,
    },
    performance: {
        title: 'عملکرد',
        name: 'MarketPerformance',
        icon: 'uil uil-comparison',
        hide: false,
    },
    technical: {
        title: 'تکنیکال',
        name: 'MarketTechnical',
        icon: 'uil uil-chart',
        hide: false,
    },
    indicator: {
        title: 'اندیکاتورها',
        name: 'MarketIndicator',
        icon: 'uil uil-tachometer-fast-alt',
        hide: false,
    },
    charts: {
        title: 'نمودارها',
        name: 'MarketCharts',
        icon: 'uil uil-chart-line',
        hide: false,
    },
    news: {
        title: 'اخبار و گزارشات',
        name: 'MarketNews',
        icon: 'uil uil-newspaper',
        hide: false,
    },
    profitloss: {
        title: 'سود و زیان',
        name: 'MarketProfitloss',
        icon: 'uil uil-percentage',
        hide: false,
    },
    archive: {
        title: 'آرشیو',
        name: 'MarketArchive',
        icon: 'uil uil-archive-alt',
        hide: true,
    }
};

export default static_market_nav;